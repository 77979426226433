import { createRouter, createWebHistory } from "vue-router";
import { auth } from "./firebase";

// Import the views
// const DecodeView = () => import("./views/DecodeView.vue");
const LoginView = () => import("./views/LoginView.vue");
const StorageView = () => import("./views/StorageView.vue");
const SupportView = () => import("./views/SupportView.vue");
// const SellView = () => import("./views/SellView.vue");
const FreezersView = () => import("./views/subviews/FreezersView.vue");
const HistoryView = () => import("./views/subviews/HistoryView.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      showHeader: true,
    },
  },
  {
    path: "/",
    name: "decode",
    component: () => import("./views/SellView.vue"),
    beforeEnter: (to, from, next) => {
      const isAuthenticated = auth.currentUser !== null;
      if (isAuthenticated) {
        return import("./views/DecodeView.vue").then((component) => {
          to.matched[0].components.default = component.default;
          next();
        });
      } else {
        return import("./views/SellView.vue").then((component) => {
          to.matched[0].components.default = component.default;
          next();
        });
      }
    },
    meta: {
      requiresAuth: false,
      header: true,
      showHeader: true,
    },
  },
  {
    path: "/freezers/:freezerId",
    name: "freezers",
    component: FreezersView,
    meta: {
      requiresAuth: true,
      showHeader: true,
    },
  },
  {
    path: "/history",
    name: "history",
    component: HistoryView,
    meta: {
      requiresAuth: true,
      showHeader: true,
    },
  },
  {
    path: "/storage",
    name: "storage",
    component: StorageView,
    meta: {
      requiresAuth: true,
      header: true,
      showHeader: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      requiresAuth: true,
      header: true,
      showHeader: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser; // Using the modular approach

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (!to.matched.length) {
    next("/");
  } else {
    next();
  }
});

export default router;
