<template>
  <div id="app">
    <header>
      <HeaderComponent />
    </header>

    <!-- This is where the component of the current route will be rendered -->
    <router-view />

    <footer>
      <!-- Footer content -->
    </footer>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
export default {
  name: "App",
  components: {
    HeaderComponent,
  },
};
</script>
