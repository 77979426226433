import "./assets/styles.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { auth } from "./firebase";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEnvelope,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEnvelope, faPhone, faArrowUp);

const app = createApp(App); // Create app instance

// Register FontAwesomeIcon component globally
app.component("font-awesome-icon", FontAwesomeIcon);

let mountedApp; // This will hold the instance once it's created

auth.onAuthStateChanged(() => {
  if (!mountedApp) {
    app.use(router);
    mountedApp = app.mount("#app");
  }
});
