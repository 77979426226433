<template>
  <div v-if="showHeader" class="mb-16">
    <div
      v-if="menuOpen && !isWideScreen"
      class="fixed top-0 left-0 w-full h-full z-10 bg-black opacity-50"
      @click="toggleMenu"
    ></div>
    <div class="bg-blue-500 text-white shadow-lg fixed w-full z-50 top-0 h-16">
      <div
        class="container mx-auto px-4 py-3 flex flex-row justify-between items-center h-full"
      >
        <div
          class="flex flex-row items-center text-xl font-bold font-quicksand cursor-pointer flex-grow"
          @click="navigateToRoot"
        >
          <img
            src="@/assets/decoder_owl.png"
            alt="Logo"
            class="h-12 w-auto rounded-full m-5"
          />
          AI Decoder
        </div>

        <div
          v-if="!isWideScreen && showButtons"
          class="relative sm:order-last z-10"
        >
          <button @click="toggleMenu" class="sm:hidden">
            <Bars3Icon class="w-10 h-10" />
          </button>
        </div>

        <div
          v-if="showButtons && (menuOpen || isWideScreen)"
          :class="
            menuOpen
              ? 'absolute top-full left-0 right-0 z-20 bg-blue-500 p-6 space-y-2'
              : 'flex space-x-4 sm:items-center'
          "
        >
          <router-link
            v-for="route in protectedRoutes"
            :key="route.name"
            :to="route.path"
            @click="toggleMenu"
            class="hover:underline capitalize block text-center sm:text-left"
          >
            {{ formatRouteName(route.name) }}
          </router-link>
          <button
            @click="logout"
            class="bg-blue-300 px-4 py-1 rounded-full mt-4 sm:mt-0 hover:bg-blue-400 block text-center mx-auto sm:mx-0"
          >
            Logout
          </button>
        </div>

        <div v-if="isWideScreen" class="relative z-10">
          <button @click="toggleMenu" class="sm:hidden">
            <Bars3Icon class="w-10 h-10" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/firebase";
import { Bars3Icon } from "@heroicons/vue/24/solid";

export default {
  components: {
    Bars3Icon,
  },
  data() {
    return {
      menuOpen: false,
      showHeader: true,
      isWideScreen: window.innerWidth > 640,
      currentUser: null,
    };
  },
  computed: {
    protectedRoutes() {
      return this.$router.options.routes.filter(
        (route) => route.meta && route.meta.header
      );
    },
    showButtons() {
      return this.currentUser !== null;
    },
  },
  methods: {
    toggleMenu() {
      if (this.isWideScreen) {
        this.menuOpen = false;
      } else {
        this.menuOpen = !this.menuOpen;
      }
    },
    navigateToRoot() {
      this.menuOpen = false;
      this.$router.push("/");
    },
    formatRouteName(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    async logout() {
      try {
        await signOut(auth);
        this.menuOpen = false;
        this.$router.push("/login");
      } catch (error) {
        console.error("Error signing out: ", error);
        // Optionally, you can notify the user about the error.
      }
    },
    async handleResize() {
      this.isWideScreen = window.innerWidth > 640;
    },
  },
  watch: {
    $route() {
      this.showHeader = this.$route.meta.showHeader === true;
    },
  },
  created() {
    auth.onAuthStateChanged((user) => {
      this.currentUser = user;
    });
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
